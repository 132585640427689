import React from 'react';
import Layout from '~/components/Layout';
import Sections from '~/components/Sections';

export default ({ pageContext }) => {
  const { content, layout, seo, socials, cookieConsent } = pageContext;

  // these have to be destructured and backed by hand since it can be that these
  // variables might not've been set in sanity cms by default. also we have to hand them
  // throu as single variables to make sure the site doesnt break in edge cases. ~DH
  const hasHeader = layout?.hasHeader || true;
  const hasFooter = layout?.hasFooter || true;

  return (
    <Layout
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      seo={seo}
      socials={socials}
      cookieConsent={cookieConsent}
    >
      {content && (
        <Sections
          content={content}
          layout={layout}
          hasHeader={hasHeader}
          hasFooter={hasFooter}
        />
      )}
    </Layout>
  );
};
